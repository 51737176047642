import React from 'react';

export const Card = ({ className, Icon, title, description }) => {
    return (
        <div className={`home-card-wrapper ${className ? className : ''}`}>
            <Icon className='home-card-icon' />
            <h3 className='home-card-title'>
                {title}
            </h3>

            <p className='home-card-description'>
                {description}
            </p>
        </div>
    );
};

