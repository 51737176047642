import React, { useState } from 'react';
import { GeneratingReport, OrderSummary, StripeElements } from './fragments';


export const OrderNow = () => {
    const [showStripe, setShowStripe] = useState(false);

    return (
        <div className='order-now-page'>
            {/*<BannerOrderNow />*/}
            <div className='container'>
                <div className='order-now-content'>
                    {showStripe ?
                        <StripeElements
                            setShowStripe={setShowStripe}
                        />
                        :
                        <GeneratingReport
                            setShowStripe={setShowStripe}
                        />
                    }
                    <OrderSummary />
                </div>
            </div>
        </div>
    );
};

