import { call, put, takeLatest } from 'redux-saga/effects';
import { ordersService } from './orders.service';
import {
    GET_ORDERS_REQUEST,
    GET_ORDERS_REQUEST_SUCCESS, SINGLE_ORDERS_REQUEST, SINGLE_ORDERS_SUCCESS,
} from './orders.types';

import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';


function* getOrders({ type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {

        const { data } = yield call(ordersService.getOrders);

        yield put({
            type: GET_ORDERS_REQUEST_SUCCESS,
            payload: {
                data,
            },
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* singleOrder({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {

        const { data } = yield call(ordersService.singleOrder, payload?.id);

        yield put({
            type: SINGLE_ORDERS_SUCCESS,
            payload: {
                data,
            },
        });

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));

    }
}


export const watchOrders = function* watchUserAuth() {
    yield takeLatest(GET_ORDERS_REQUEST, getOrders);
    yield takeLatest(SINGLE_ORDERS_REQUEST, singleOrder);
};
