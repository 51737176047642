import React from 'react';
import { DeleteAccount, PastOrders } from './fragments';

export const Orders = () => {
    return (
        <div className='orders-page'>
            <div className='container'>
                <PastOrders />
                <DeleteAccount />
            </div>
        </div>
    );
};

