import axios from 'axios';


export const ordersService = {
    getOrders: () => axios.get(`/orders`, { auth: true }),

    ordersPost: (data) => {
        const token = localStorage.getItem('access-token');
        return axios.post(`/orders`, { ...data }, { auth: !!token })
    },

    singleOrder: (id) => axios.get(`/orders/${id}`),
};
