import React from 'react';
import { FadeLoader } from 'react-spinners';

export const FadeLoaderC = ({ color, height, width, margin, radius, big }) => {
    return (
        <FadeLoader
            color={color ? color : '#347AF0'}
            height={height ? height : big ? 34 : 15}
            width={width ? width : big ? 10 : 5}
            margin={margin ? margin : big ? 20 : 2}
            radius={radius ? radius: big ? 40 : 2}
        />
    );
};

