import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { ButtonMain, CheckboxMain } from 'components';
import { ordersService } from 'store/orders/orders.service';
import { httpRequestsOnErrorsActions } from 'store';

export const CheckoutForm = ({ setShowStripe }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errors, setErrors] = useState({});
  const [iAccept, setIAccept] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    setErrors({});

    if (!stripe || !elements) {
      return;
    }

    try {
      if (state?.coupon?.id) {
        const { data } = await ordersService.ordersPost({
          address: state?.address,
          email: state?.email,
          claimNumber: state?.claimNumber,
          coupon: state?.coupon?.code,
        });

        navigate(`/report/${data?.id}`);

      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: event.target.name.value,
          },
        });

        if (error) {
          switch (error.code) {
            case 'incomplete_number':
            case 'invalid_number':
              setErrors(prev => (
                {
                  ...prev,
                  cardNumber: 'Please enter a valid card number.',
                }
              ));
              break;
            case 'incomplete_expiry':
            case 'invalid_expiry_year_past':
            case 'invalid_expiry_year':
            case 'invalid_expiry_month':
              setErrors(prev => (
                {
                  ...prev,
                  expiry: 'Please enter a valid expiry date.',
                }
              ));
              break;
            case 'incomplete_cvc':
            case 'invalid_cvc':
              setErrors(prev => (
                {
                  ...prev,
                  cvc: 'Please enter a valid CVV.',
                }
              ));
              break;
            default:
              setErrors(prev => ({
                ...prev,
                els: error.message,
              }));
          }

        } else {
          const { data } = await ordersService.ordersPost({
            address: state?.address,
            email: state?.email,
            claimNumber: state?.claimNumber,
            pmtMethod: paymentMethod?.id,
          });

          navigate(`/report/${data?.id}`);
        }
      }

    } catch (e) {
      if (e?.data?.message === 'Invalid address format or location not found.') {
        dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
        setShowStripe(false);
      }

      if (e?.data?.message === 'Cannot generate report for the address entered') {
        dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
        setShowStripe(false);
      }

      setErrors(prev => ({
        ...prev,
        els: e.data?.message,
      }));

    } finally {
      setLoader(false);
    }

  };

  return (
      <form className='checkoutForm-wrapper' onSubmit={handleSubmit}>
        {!state?.coupon?.id &&
            <>
              <div className='cardholder-block'>
                <label className='checkoutForm-label'>
                  Card Holder Name:
                  <input
                      name='name'
                      type='text'
                      placeholder='Name Surname'
                      required
                      className='checkoutForm-input'
                  />
                </label>

                <p className='card-errors'>{errors?.name}</p>
              </div>

              <div className='credit-card-number'>
                <label className='checkoutForm-label'>
                  Credit Card Number:
                  <CardNumberElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#152E47',
                            '::placeholder': {
                              color: '#aab7c4',
                            },
                          },
                          invalid: {
                            color: 'red',
                          },
                        },
                        classes: {
                          base: 'checkoutForm-input',
                          invalid: 'checkoutForm-input-error',
                        },
                        placeholder: 'xxxx xxxx xxxx xxxx',
                      }}
                  />
                  <p className='card-errors'>{errors?.cardNumber}</p>
                </label>
              </div>

              <div className='expiry-date-cvv'>
                <div className='credit-card-expiry-date'>
                  <label className='checkoutForm-label'>
                    Expiry Date:
                    <CardExpiryElement options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#152E47',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#1e2446',
                        },
                      },
                      classes: {
                        base: 'checkoutForm-input',
                        invalid: 'checkoutForm-input-error',
                      },
                      placeholder: 'MM / YY',
                    }} />
                  </label>

                  <p className='card-errors'>{errors?.expiry}</p>
                </div>

                <div className='credit-card-cvv'>
                  <label className='checkoutForm-label'>
                    CVV:
                    <CardCvcElement options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#152E47',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#1e2446',
                        },
                      },
                      classes: {
                        base: 'checkoutForm-input',
                        invalid: 'checkoutForm-input-error',
                      },
                      placeholder: 'XXX',
                    }} />
                  </label>

                  <p className='card-errors'>{errors?.cvc}</p>
                </div>
              </div>
              <p className='card-errors'>{errors?.els}</p>

              <div className='checkbox-text-block'>
                <CheckboxMain
                    checked={iAccept}
                    onChange={() => {
                      setIAccept(prev => !prev);
                    }}
                />

                <p className='checkbox-text'>
                  I accept the
                  <NavLink
                      to='/terms'
                      className='checkbox-text-link'
                      target='_blank'
                  >
                    ClaimFRV Terms of Service.
                  </NavLink>
                </p>

              </div>
            </>
        }
        {state?.coupon?.id &&
        <p style={{textAlign:'center', fontSize:'14px'}} className='card-errors'>{errors?.els}</p>
        }
        <ButtonMain
            type='submit'
            text='Generate Report'
            className='generate-report-button'
            disabled={(!state?.coupon?.id && (!stripe || !iAccept))}
            loading={loader}
        />

        <p className='generate-report-under-text'>
          Review your order details before finalizing your purchase. Ensure the accuracy of
          the property address and chosen options.
        </p>
      </form>
  );
};