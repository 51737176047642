import React from 'react';
import { useNavigate } from 'react-router';
import { ButtonMain } from 'components';
import { Svg, Images } from 'assets';

export const SimpleSteps = () => {
  const navigate = useNavigate();

  const simpleSteps = [
    {
      Svg: Svg.List,
      step: 'Step 1',
      title: 'Submit Property Address',
      desc: 'Input the complete address of your Subject Property and provide an email address to receive your FRV report.',
    },

    {
      Svg: Svg.TransactionHistory,
      step: 'Step 2',
      title: 'Make a Payment',
      desc: `Proceed to our safe and encrypted payment process. Once your payment is confirmed, our AI will immediately get to work. `,
    },

    {
      Svg: Svg.Growth,
      step: 'Step 3',
      title: 'Receive Your Report',
      desc: `Your FRV report will be delivered within seconds to the provided email address. Access your report anytime by logging into your online account.`,
    },
  ];

  return (
    <div className="container">
      <div className="simple-steps-wrapper">
        <h2 className="simple-steps-title">Getting your report is simple</h2>

        <div className="simple-steps-box">
          <div className="simple-steps-img">
            <img src={Images.SimplePng} alt="simple steps" />
          </div>

          <div className="single-steps-block">
            <div className="single-steps-block-info">
              {simpleSteps.map(({ Svg, title, desc, step }, key) => (
                <div key={key} className="single-simple-steps">
                  <div className="single-simple-steps-svg-block">
                    <Svg />
                  </div>

                  <div className="single-simple-title-desc">
                    <p className="single-simple-step">{step}</p>

                    <h4 className="single-simple-title">{title}</h4>

                    <p className="single-simple-desc">{desc}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="steps-button_block">
              <div className="steps-button_block-line" />
              <ButtonMain
                startAdornment={<Svg.Listing />}
                text="Get Your FRV Report Now"
                className="steps-button"
                onClick={() => navigate('order-now')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
