import React from 'react';
import { Loader } from 'components';


export const ButtonMain = (
    {
        text, loading, disabled, className, contained = true,
        type = 'button', onClick, startAdornment, endAdornment, isError,
        withOutPadding, textColor, loadStyles, cancel, deleteButton,
    }) => {
    return (
        <button
            type={type}
            disabled={disabled || loading}
            onClick={onClick}
            className={`button-main ${contained ? 'contained' : 'outlined'}
            ${cancel ? 'cancel-button' : ''}
            ${deleteButton ? 'delete-button' : ''}
            ${withOutPadding ? 'padding-0' : ''}
             ${isError ? 'error_button' : ''}
              ${className ? className : ''}`
            }
        >
            {loading ?
                <Loader styles={{ color: isError ? '#d03325' : contained ? '#FFF' : '#347AF0', ...loadStyles }} />
                : <div className='flex-all-center'>
                    {startAdornment && <div className='button-start-adornment'>{startAdornment}</div>}
                    <span style={{ color: textColor ? textColor : '' }}>
                        {text}
                    </span>
                    {endAdornment && <div className='button-end-adornment'>{endAdornment}</div>}
                </div>
            }
        </button>
    );
};

