import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ButtonMain, InputMain } from 'components';
import { logIn } from 'store/auth/auth.action';
import { EmailValidator, FindError, FindLoad, FindSuccess, useModal } from 'utils';
import { Images, Svg } from 'assets';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { ForgotPassword } from './forgotPassword';
import { useNavigate } from 'react-router';


const REQUEST_TYPE = 'LOG_IN';

export const LogIn = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { openModal, close } = useModal();
    const backError = FindError(REQUEST_TYPE);
    const findLoading = FindLoad('GET_MY_PROFILE');
    const loadLogIn = FindLoad('LOG_IN');
    const successLogin = FindSuccess('GET_MY_PROFILE');

    const {
        handleSubmit,
        control,
        setError,
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        };
    }, []);

    useEffect(() => {
        if (successLogin) {
            navigate('/')
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess('GET_MY_PROFILE'));

        }
    }, [successLogin]);


    useEffect(() => {
        if (backError?.error === 'Such user does not exist in our records') {
            setError('email', {
                type: 'required',
                message: 'Such user does not exist in our records',
            });
        }

        if (backError?.error === 'User password does not match') {
            setError('password', {
                type: 'required',
                message: 'User password does not match',
            });
        }
    }, [backError]);


    const onSubmit = (data) => {
        dispatch(logIn(data));
    };


    const handleClickIcon = () => {
        setShowPassword(prev => !prev);
    };

    const openForgotModal = () => {
        close();
        openModal(<ForgotPassword />);
    };

    return (
        <div className='login-wrapper'>
            <div className='login-img-block'>
                <img src={Images.LoginImg} alt='logIn Img' />
            </div>

            <div className='login-content'>
                <h3 className='login-title'>
                    Sign In to ClaimFRV
                </h3>

                <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
                    <InputMain
                        name='email'
                        type='email'
                        control={control}
                        placeholder='Email'
                        labelHeader='Email Address'
                        rules={{
                            required: 'This Field is required',
                            pattern: {
                                value: EmailValidator,
                                message: 'Must be valid email',
                            },
                        }}
                        startAdornment={<Svg.Sms />}
                    />

                    <InputMain
                        name='password'
                        type='password'
                        showPassword={showPassword}
                        control={control}
                        placeholder='Password'
                        labelHeader='Password'
                        rules={{
                            required: 'This Field is required',
                        }}
                        onClickIconEnd={handleClickIcon}
                        startAdornment={<Svg.Lock />}
                    />

                    <ButtonMain
                        loading={loadLogIn || findLoading}
                        text='Sign In'
                        className='login-sign-in-button'
                        type='submit'
                    />
                </form>

                <div className='flex-all-center'>
                    <button onClick={openForgotModal} className='log_forgot-sign-up-btn log_forgot-btn'>
                        Forgot Password?
                    </button>
                </div>

                <div className='dont-have'>
                    <span>Don&apos;t have a ClaimFRV account?</span>
                    <button className='log_forgot-sign-up-btn sign_forgot-btn'
                            onClick={() => {
                                navigate('sign-up')
                                close()
                            }}
                    >
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

