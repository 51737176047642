import React from 'react';
import { Checkbox } from '@mui/material';

export const CheckboxMain = ({ checked, onChange, className }) => {
    return (
        <div className={`checkbox-box ${className ? className : ''}`}>
            <Checkbox
                checked={checked}
                onChange={onChange}
                sx={{
                    color: '#347AF0',
                }}
            />
        </div>
    );
};

