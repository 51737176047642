import React, { forwardRef } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const InputMain = forwardRef(
  ({
     customDesign = true,
     label, labelHeader, placeholder, rules, control, name, type = 'text', maxLength, helperText,
     startAdornment, endAdornment, className, onClickIconStart, onClickIconEnd, showPassword, ...anotherProps
   }, ref) => {

    const EndIcon = showPassword ? Visibility : VisibilityOff;
    const typePassword = showPassword ? 'text' : 'password';

    return (
      <div
        className={`input-main-wrapper ${customDesign ? 'input-custom-wrapper' : ''} ${className ? className : ''}`}
        ref={ref}>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, formState: { errors } }) => (
            <>
              {labelHeader
                &&
                <p className={`input-main-label-header ${errors[field?.name]?.message ? 'errorLabel' : ''}`}>
                  {labelHeader}
                  {rules?.required
                    && <span className='input-main-label-header-required'>
                                        *
                                </span>
                  }
                </p>
              }
              <TextField
                ref={ref}
                {...anotherProps}
                {...field}
                autoComplete='on'
                error={!!errors[field?.name]?.message}
                type={showPassword ? typePassword : type}
                value={field.value}
                placeholder={placeholder}
                label={label}
                inputProps={{ maxLength: maxLength }}
                InputProps={{
                  startAdornment: startAdornment &&
                    <InputAdornment
                      onClick={onClickIconStart}
                      className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                      position='start'
                    >
                      {startAdornment}
                    </InputAdornment>,
                  endAdornment:
                    (endAdornment && <InputAdornment
                      onClick={onClickIconEnd}
                      position='end'
                      className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                    >
                      {endAdornment}
                    </InputAdornment>)
                    || (type === 'password'
                      && <InputAdornment
                        onClick={onClickIconEnd}
                        position='end'
                        className={`${errors[field?.name]?.message ? 'error-svg-password' : ''}`}
                      >
                        <EndIcon />
                      </InputAdornment>),
                }}
              />
              {(helperText && !(errors[field?.name]?.message))
                ? <p className='helper-text'>
                  {helperText}
                </p>
                : <p className='error-message'>{errors[field?.name]?.message}</p>
              }
            </>
          )}
        />
      </div>
    );
  });

InputMain.displayName = 'InputMain';


