import { Fragment, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CreateAccount, Layout, ResetPassword } from 'fragments';
import { Home, OrderNow, Orders, NotFound, Terms, Privacy, ContactUs, FrvReport } from 'pages';

export const Routers = () => {
    const token = localStorage.getItem('access-token');
    const link = window.location.search;

    useEffect(() => {
        if (link.search("resetToken=") !== -1) {
            localStorage.removeItem('access-token');
            localStorage.removeItem('userInfo');
        }
    }, []);

    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path='/order-now' element={<OrderNow />} />
                    <Route path='/report/:id' element={<FrvReport />} />
                    {/*{!token &&*/}
                        <Route path='/resetPassword/:resetToken?' element={<ResetPassword />} />
                    {/*}*/}
                    {token
                        && <Route path='/orders' element={<Orders />} />
                    }
                    <Route path='/contact-us' element={<ContactUs />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/sign-up' element={<CreateAccount />} />
                    <Route path='*' element={<NotFound />} />
                </Route>

            </Routes>
        </Fragment>
    );
};
