import React from 'react';

export const Privacy = () => {
  return (
    <div className="terms-page">
      <div className="container">
        <h2 className="terms-title">Privacy & Policy</h2>

        <div className="terms-text-block">
          <p className="terms-text">
            Welcome to ClaimFRV! We are committed to protecting your privacy and ensuring you have a positive experience on our website and
            in using our services. This Privacy Policy applies to our website, claimfrv.com, and governs our data collection, processing,
            and usage practices. By using the website, you consent to the data practices described in this policy.
          </p>

          <p className="terms-text">
            1. Information We Collect Personal Information: When you register for an account, you provide us with personal information that
            includes your name, email address, and payment details. Transactional Information: We collect information related to your
            transactions on our website, including the purchase of FRV reports. Automated Information: When you visit our website, we
            automatically collect information sent to us by your computer, mobile device, or other access device. This includes your IP
            address, device information, browser type, and usage data.
          </p>

          <p className="terms-text">
            2. How We Use Your Information We use the information we collect for the following purposes: To provide and improve our
            services, including generating and maintaining your FRV reports. To manage your account and provide you with customer support.
            To process transactions and send notices about your transactions. For compliance with our legal obligations, resolve disputes,
            and enforce our agreements.
          </p>

          <p className="terms-text">
            3. How We Share Information Service Providers: We share your information with third-party service providers who perform services
            on our behalf, such as payment processing and data analysis. Legal Requirements: We may disclose your information if required to
            do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).
          </p>

          <p className="terms-text">
            4. How We Protect Your Information We implement a variety of security measures to maintain the safety of your personal
            information when you enter, submit, or access your personal information. Despite our efforts, no security measures are
            completely secure, and we cannot guarantee the absolute security of your information.
          </p>
          <p className="terms-text">
            5. Use of Cookies and Other Tracking Technology We use cookies and similar tracking technologies to track the activity on our
            website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
          </p>
          <p className="terms-text">
            6. Accessing and Updating Your Personal Information You have the right to request access to the personal information we hold
            about you and to ask for your personal information to be corrected, updated, or deleted. If you would like to exercise these
            rights, please contact us at claimfrv@gmail.com.
          </p>
          <p className="terms-text">
            7. Changes to This Privacy Policy We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to
            the change becoming effective.
          </p>

          <p className="terms-text">
            8. Contact Us If you have any questions about this Privacy Policy, please contact us at claimfrv@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};
