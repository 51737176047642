import React from 'react';
import { Images, Svg } from 'assets';
import { useNavigate } from 'react-router';
import { ButtonMain } from 'components';
import { useModal } from 'utils';

export const WelcomeTo = () => {
    const { close } = useModal();
    const navigate = useNavigate();

    const closeModalAndNavigate = () => {
        navigate('/');
        close();
    };
    return (
        <div className='login-wrapper'>
            <div className='welcome-to-img-block'>
                <img src={Images.LoginImg} alt='logIn Img' />
            </div>

            <div className='login-content welcome-content'>
                <Svg.CloseSvg
                    className='close-button'
                    onClick={() => close()}
                />

                <div className='welcome-to-content-box'>
                    <p className='login-title'>
                        Welcome to ClaimFRV!
                    </p>

                    <p className='forgot-desc'>
                        You are all set up. Now you&apos;re ready to start your journey within ClaimFRV.
                    </p>

                    <ButtonMain
                        className='done-button'
                        text='Done'
                        onClick={closeModalAndNavigate}
                    />
                </div>
            </div>
        </div>
    );
};

