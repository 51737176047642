import React from 'react';
import { useNavigate } from 'react-router';
import { ButtonMain } from 'components';
import { Svg } from 'assets';

export const NoData = () => {
    const navigate = useNavigate();

    return (
        <div className='no-data-wrapper'>
            <Svg.NoDataSvg className='no-data-svg'/>

            <h4 className='no-data-title'>
                No Past Orders Found
            </h4>

            <p className='no-data-text'>
                You haven&apos;t placed any orders yet. Once you do, you&apos;ll see them listed here. Ready to get started?
            </p>

            <ButtonMain
                text='Get Your FRV Report Now'
                className='no-data-button'
                startAdornment={<Svg.Listing />}
                onClick={() => navigate('/order-now')}
            />
        </div>
    );
};

