import React from 'react';
import { Images } from 'assets';
import { ButtonMain } from 'components';
import { useModal } from 'utils';

export const CheckEmail = ({email}) => {
    const { close } = useModal();

    const handleSubmit = (e) => {
        e.preventDefault();
        close();
    };
    return (
        <div className='login-wrapper'>
            <div className='check-email-img-block'>
                <img src={Images.LoginImg} alt='logIn Img' />
            </div>

            <div className='login-content'>
                <h3 className='login-title'>
                    Please Check Your Email
                </h3>

                <p className='forgot-desc'>
                    We&apos;ve sent a recovery email to <span className='check-email'>{email}</span> reset
                    your password.
                </p>

                <form className='login-form' onSubmit={handleSubmit}>
                    <ButtonMain
                        text='OK'
                        className='login-sign-in-button'
                        type='submit'
                    />
                </form>
            </div>
        </div>
    );
};

