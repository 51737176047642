import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AddressInput, ButtonMain, CheckboxMain, InputMain } from 'components';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { EmailValidator, FindError, FindLoad, FindSuccess, PasswordValidator } from 'utils';
import { Svg } from 'assets';
import { couponsService } from 'store/coupons/coupons.service';

const REQUEST_TYPE = 'SIGN_UP';
const GET_PROFILE_ACTION_TYPE = 'GET_MY_PROFILE';
const ADDRESS_ACTION_TYPE = 'ORDER_ADDRESS_FAIL';
const LOGIN_ACTION_TYPE = 'LOG_IN';

export const GeneratingReport = ({ setShowStripe }) => {
  const [iAgree, setIAgree] = useState(false);
  const [choose, setChoose] = useState(false);
  const [couponLoad, setCouponLoad] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const backError = FindError(REQUEST_TYPE);
  const addressError = FindError(ADDRESS_ACTION_TYPE);
  const signUpLoad = FindLoad(REQUEST_TYPE);
  const signInLoad = FindLoad(LOGIN_ACTION_TYPE);
  const getProfileLoad = FindLoad(GET_PROFILE_ACTION_TYPE);
  const signUpSuccess = FindSuccess(GET_PROFILE_ACTION_TYPE);
  const token = localStorage.getItem('access-token');
  const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    defaultValues: {
      address: state?.address || '',
      email: state?.email || userInfo?.email || '',
      claimNumber: state?.claimNumber || '',
      // coupon:  '',
      fullName: '',
      userEmail: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (userInfo) {
      const params = {};
      state?.email ? params.email = state.email :
        userInfo?.email ? params.email = userInfo.email : delete params.email;

      state?.address ? params.address = state?.address : delete params.address;
      reset({
        ...params,
      });
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      const params = {
        ...state
      };
      state?.coupon?.code ? params.coupon = state?.coupon?.code : delete params.coupon;
      reset({
        ...params,
      });
    }
  }, [state]);

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    return () => {
      clearErrors();
      dispatch(httpRequestsOnErrorsActions.removeError(ADDRESS_ACTION_TYPE));
      dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
    };
  }, []);

  useEffect(() => {
    if (backError?.error) {
      dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
      setError('userEmail', {
        type: 'required',
        message: 'User already exists',
      });
    }
    if (addressError?.error) {
      dispatch(httpRequestsOnErrorsActions.removeError(ADDRESS_ACTION_TYPE));
      setError('address', {
        type: 'required',
        message: addressError.error,
      });
    }
  }, [backError, addressError]);


  useEffect(() => {
    if (signUpSuccess && choose) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(GET_PROFILE_ACTION_TYPE));
      setShowStripe(true);
    }
  }, [signUpSuccess]);


  const onSubmit = async (data) => {
    const params = {};

    if (Object.keys(errors).length === 0) {
      if ((token || !choose) && !data?.coupon) {
        setShowStripe(true);
      }

      if (!token && iAgree) {
        const { fullName, userEmail, password, confirmPassword } = data;

        const loggedData = {
          fullName,
          email: userEmail,
          password,
        };

        if (password === confirmPassword) {
          dispatch(authActions.signUp(loggedData));
        } else {
          setError('confirmPassword', {
            type: 'required',
            message: 'Password does not match with the confirmation',
          });
        }
      }


      if (data?.coupon) {
        setCouponLoad(true);

        try {
          const response = await couponsService.checkCoupon(data?.coupon);

          response?.data ? params.coupon = response?.data : delete params.coupon;

          if (response?.data) setShowStripe(true);

        } catch (e) {
          const errorMessage = e?.data?.message || e?.message;

          if (errorMessage === 'Cannot generate report for the address entered') {
            setError('address', {
              message: errorMessage,
            });
          } else {
            setError('coupon', {
              message: errorMessage,
            });
          }

          setCouponLoad(false);

        } finally {
          setCouponLoad(false);
        }

      }

      data?.address ? params.address = data?.address : delete params.address;
      data?.email ? params.email = data?.email : delete params.email;
      data?.claimNumber ? params.claimNumber = data?.claimNumber : delete params.claimNumber;
      navigate('/order-now', { state: params });
    }
  };

  const showingPasswords = (path) => {
    setShowPasswords((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  return (
    <div className='generating-report-wrapper'>
      <h2 className='generating-report-title'>
        Maximize FRV Accuracy and speed with AI
      </h2>

      <p className='generating-report-description'>
        Enhance your assessments today-experience the ClaimFRV advantage in every report
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputMain
          name='claimNumber'
          control={control}
          placeholder='Maximum 15 characters'
          labelHeader='Claim# (Optional)'
          maxLength={15}
          // type="number"
          rules={{
            maxLength: {
              value: 15,
              message: 'Max length exceeded',
            },
          }}
        />

        <AddressInput
          name='address'
          control={control}
          placeholder='Address'
          labelHeader='Address'
          setValue={setValue}
          rules={{
            required: 'This Field is required',
          }}
        />

        <InputMain
          name='email'
          control={control}
          placeholder='Email'
          labelHeader='Email'
          rules={{
            required: 'This Field is required',
            pattern: {
              value: EmailValidator,
              message: 'Must be valid email',
            },
          }}
          startAdornment={<Svg.Sms />}
        />

        {/*<InputMain*/}
        {/*  name='coupon'*/}
        {/*  control={control}*/}
        {/*  placeholder='Enter your coupon'*/}
        {/*  labelHeader='Coupon Code'*/}
        {/*  helperText='Enter your 6-character coupon code to make the report free'*/}
        {/*  rules={{*/}
        {/*    maxLength: {*/}
        {/*      value: 6,*/}
        {/*      message: 'Coupon code must be exactly 6 characters',*/}
        {/*    },*/}
        {/*    minLength: {*/}
        {/*      value: 6,*/}
        {/*      message: 'Coupon code must be exactly 6 characters',*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  maxLength={6}*/}
        {/*  startAdornment={<Svg.CouponSvg className={errors?.coupon?.message ? 'coupon-svg-error' : ''} />}*/}
        {/*/>*/}

        {!token && (
          <div className='choose-generate-report'>
            <CheckboxMain checked={choose} onChange={() => setChoose((prev) => !prev)} />
            <p className='choose-generate-report-text'>Create an account for additional benefits</p>
          </div>
        )}
        {choose && (
          <div className='create-ac-form'>
            <InputMain
              name='fullName'
              control={control}
              placeholder='Full Name'
              labelHeader='Full Name'
              rules={{
                required: 'This Field is required',
              }}
              startAdornment={<Svg.Profile
                className={`${errors?.fullName?.message ? 'error-profile' : ''}`} />}
            />

            <InputMain
              name='userEmail'
              control={control}
              placeholder='Email'
              labelHeader='Email'
              rules={{
                required: 'This Field is required',
                pattern: {
                  value: EmailValidator,
                  message: 'Must be valid email',
                },
              }}
              startAdornment={<Svg.Sms />}
            />

            <InputMain
              name='password'
              type='password'
              showPassword={showPasswords.password}
              control={control}
              placeholder='Password'
              labelHeader='Password'
              rules={{
                required: 'This Field is required',
                pattern: {
                  value: PasswordValidator,
                  message: 'Not valid Password',
                },
              }}
              onClickIconEnd={() => showingPasswords('password')}
              startAdornment={<Svg.Lock />}
            />

            <InputMain
              name='confirmPassword'
              type='password'
              showPassword={showPasswords.confirmPassword}
              control={control}
              placeholder='Password Confirmation'
              labelHeader='Password Confirmation'
              rules={{
                required: 'This Field is required',
              }}
              onClickIconEnd={() => showingPasswords('confirmPassword')}
              startAdornment={<Svg.Lock />}
            />

            <div className='i-have-read'>
              <CheckboxMain checked={iAgree} className='i-have-read-checkbox'
                            onChange={() => setIAgree((prev) => !prev)} />
              <p className='i-have-read-text'>
                I have read and agree to the
                <NavLink className='i-have-read-text-link' to='/terms' target='_blank'>
                  Terms of Use
                </NavLink>
                and
                <NavLink className='i-have-read-text-link' to='/privacy' target='_blank'>
                  Privacy Policy
                </NavLink>
              </p>
            </div>
          </div>
        )}

        <ButtonMain
          loading={signUpLoad || signInLoad || getProfileLoad || couponLoad}
          text='Continue'
          type='submit'
          disabled={!iAgree && !token && choose}
          className='continue-button'
        />
      </form>

      <p className='review-your-order-text'>
        Review your order details before finalizing your purchase. Ensure the accuracy of the property address
        and chosen options.
      </p>
    </div>
  );
};
