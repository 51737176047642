import { GET_ORDERS_REQUEST_SUCCESS, SINGLE_ORDERS_SUCCESS } from './orders.types';


const initialState = {
    orders: null,
    singleOrder: null,
};

export const ordersReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ORDERS_REQUEST_SUCCESS:
            return {
                ...state,
                orders: action.payload?.data,
            };
        case SINGLE_ORDERS_SUCCESS:
            return {
                ...state,
                singleOrder: action.payload?.data,
            };

        default:
            return state;
    }
};
