import React from 'react';
import { Svg } from 'assets';
import { Loader, PriceRow } from 'components';

export const PastOrdersCard = ({ order, handleDownload, loading }) => {
  return (
    <div className="PastOrdersCard-wrapper">
      <div className="PastOrdersCard-header">
        <Svg.DocumentSvg />
        {order?.address?.formattedAddress}
      </div>

      <div className="PastOrdersCard-text-block">
        <p className="PastOrdersCard-text">
          Date Paid:<span className="PastOrdersCard-desc">{order?.createdAt}</span>
        </p>

        <p className="PastOrdersCard-text">
          Price:<span className="PastOrdersCard-desc">
            <PriceRow info={order?.paymentAmount ? +order?.paymentAmount : 0}/>
           </span>
        </p>
      </div>

      <div className="PastOrdersCard-svgs">
        <div className="svg-block">
          <a href={order?.pdfFile?.url} rel="noreferrer" target={'_blank'}>
            <Svg.Eye />
          </a>
        </div>

        <div className="svg-block">
          {loading ? (
            <Loader styles={{ color: '#347AF0', width: 18, height: 18 }} />
          ) : (
            <Svg.DownloadSvg onClick={() => handleDownload(order?.pdfFile)} />
          )}
        </div>
      </div>
    </div>
  );
};
