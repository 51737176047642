import React, { useCallback, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import { Tooltip } from '@mui/material';

export const DateRow = ({ date, color }) => {
    return (
        <p style={color ? { color: color } : {}}>
            {date ? moment(date).format('MM/DD/YYYY') : 'N/A'}
        </p>
    );
};

export const TextRow = ({ name, textWidth, color }) => {
    const [currentWidth, setCurrentWidth] = useState(null);
    const width = currentWidth ? currentWidth : 'auto';
    const text = (name ? name : '...') + '';

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    return (
        <div ref={curr} className="full-width">{RenderText(width, text, textWidth, color)}</div>
    );
};

export const PriceRow = ({ info, textWidth }) => {
    const [currentWidth, setCurrentWidth] = useState(null);
    const width = currentWidth ? currentWidth : 250;
    const price = (info ? info.toFixed(2) : 0) + '';

    const curr = useCallback(node => {
        if (node !== null) {
            setCurrentWidth(node.getBoundingClientRect().width);
        }
    }, []);

    if (price) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + price?.slice(0, count);
        return <div ref={curr} className="full-width">
            {price?.length > count ?
                <Tooltip placement="top" title={
                    <p>
                        <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix='$'/>
                    </p>
                }>
                    <p style={{ textOverflow: 'ellipsis' }}>
                        <NumericFormat value={slicedText} displayType={'text'} thousandSeparator={true} prefix='$'/>...
                    </p>
                </Tooltip>
                :
                <p>
                    <NumericFormat value={price} displayType={'text'} thousandSeparator={true} prefix='$' />
                </p>
            }
        </div>;
    } else {
        return '...';
    }
};

export const PhoneRow = ({ phone }) => {
    if (phone) {
        const converted = '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6);
        return (
            converted
        );
    } else {
        return 'Not Set';
    }
};

export const TypeRow = ({ text }) => {
    if (text !== undefined) {
        return (
            text?.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
                return g1.toUpperCase() + g2.toLowerCase();
            })
        );
    } else {
        return '...';
    }
};

const RenderText = (width, text, textWidth, color) => {
    if (text) {
        const count = Math.ceil(width / (textWidth ? textWidth : 11));
        const slicedText = '' + text?.slice(0, count);

        return <div className="full-width" style={color ? { color: color } : {}}>
            {text?.length > count ?
                <Tooltip placement="top" title={<p>{text}</p>}>
                    <p style={{ textOverflow: 'ellipsis' }}>
                        {`${slicedText}...`}
                    </p>
                </Tooltip>
                :
                <p>{text}</p>
            }
        </div>;
    } else {
        return '...';
    }
};

export const PriceConvertor = ({ price }) => {
    const convertedPrice = price ? price.toFixed(2) : 0;
    return (
        <NumericFormat
            value={convertedPrice ? convertedPrice : '0'}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
        />
    );
};