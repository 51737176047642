/** Types */
export const SIGN_UP = 'SIGN_UP';

export const LOG_IN = 'LOG_IN';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const LOG_OUT = 'LOG_OUT';
export const GET_RECOVERY_LINK = 'GET_RECOVERY_LINK';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_CUSTOMER_INFO_REQUEST = 'CHANGE_CUSTOMER_INFO_REQUEST';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const CONTACT_US = 'CONTACT_US'
