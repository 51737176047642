import React, { useState, Fragment, useContext, createContext } from 'react';
import { Fade, Modal } from '@mui/material';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [params, setParams] = useState({});
    const [modalInfo, setModalInfo] = useState({});

    return (
        <Fragment>
            <ModalContext.Provider
                value={{
                    params,
                    setParams,
                    modalInfo,
                    setModalInfo,
                }}>
                {children}
            </ModalContext.Provider>
        </Fragment>
    );
};

export const useModal = () => {
    const { params, setParams, modalInfo, setModalInfo } = useContext(ModalContext);

    return {
        openModal: (params = {}, modalInfo) => {
            setParams(params);
            setModalInfo(modalInfo);
        },
        close: () => {
            setParams({});
            setModalInfo({});
        },
        params,
        modalInfo,
    };
};


export const Modals = () => {
    const { params, close } = useModal();
    const renderOpen = Object.keys(params).length !== 0;

    return (
        <div>
            {renderOpen &&
                <Modal
                    aria-labelledby='transition-modal-title'
                    aria-describedby='transition-modal-description'
                    open={renderOpen}
                    onClose={() => close()}
                    closeAfterTransition
                    hideBackdrop={false}
                >
                    <Fade in={renderOpen}>
                        <div
                            // className={classes.PopoverUserInfo}
                        >
                            {/*<CloseButton handleClick={handleClick} type={'info'} />*/}

                            {params}
                        </div>
                    </Fade>
                </Modal>
            }
        </div>
    );
};