import React from 'react';
import { ButtonMain } from 'components';
import { useNavigate } from 'react-router';
import { Svg } from 'assets';

export const ReadyToDiscover = () => {
    const navigate = useNavigate();

    return (
        <div className='container'>
            <div className='ready-to-wrapper'>
                <h2 className="ready-to-title">
                    Maximize FRV Accuracy and speed with AI
                </h2>

                <p className='ready-to--desc'>
                    Enhance your assessments today—experience the ClaimFRV advantage in every report.
                </p>

                <ButtonMain
                    text="Get Your FRV Report Now"
                    className='ready-to-button'
                    startAdornment={<Svg.Listing />}
                    onClick={() => navigate('order-now')}
                />
            </div>
        </div>
    );
};

