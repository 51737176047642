import React from 'react';
import { Images } from 'assets';

export const WorkWithUs = () => {
  const counts = [
    {
      count: '10,000+',
      description: 'Reports Generated',
    },
    {
      count: '22+',
      description: 'Years of Experience',
    },
  ];

  return (
    <div className="work-with-us-wrapper" id="work-with-us">
      <div className="container">
        <div className="work-with-us-box">
          <div className="work-with-us-figcaption">
            <div>
              <h3 className="work-with-us-title">Proven Expertise, Trusted Performance</h3>

              <p className="work-with-us-description">
                Discover why countless professionals choose ClaimFRV for their Fair Value Estimates.{' '}
              </p>
            </div>

            <div className="info-counts">
              {counts.map(({ count, description }, index) => (
                <div className="info-count-single" key={index}>
                  <p className="info-count">{count}</p>
                  <p className="info-desc">{description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="work-with-u-img">
            <img src={Images.WorkWithUs} alt="work with us" />
          </div>
        </div>
      </div>
    </div>
  );
};
