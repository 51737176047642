import { combineReducers } from 'redux';
import { httpRequestsOnLoadReducer } from '../http_requests_on_load';
import { httpRequestsOnSuccessReducer } from '../http_requests_on_success';
import { httpRequestsOnErrorsReducer } from '../http_requests_on_errors';
import { authReducer } from '../auth';
import { ordersReducer } from '../orders';


export const appReducer = combineReducers({
    auth: authReducer,
    orders:ordersReducer,
    httpOnLoad: httpRequestsOnLoadReducer,
    httpOnSuccess: httpRequestsOnSuccessReducer,
    httpOnError: httpRequestsOnErrorsReducer,
});