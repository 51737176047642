import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from 'store';
import { DeleteModal } from 'fragments';
import { useModal } from 'utils';

export const DeleteAccount = () => {
    const { openModal } = useModal();
    const dispatch = useDispatch();
    const myProfile = useSelector(state => state.auth.myProfile);

    const deleteAccount = () => {
        dispatch(authActions.deleteAccount(myProfile?.id));
    };


    const openingDeleteModal = () => {
        openModal(
            <DeleteModal
                onDelete={deleteAccount}
                loadType='DELETE_ACCOUNT_REQUEST'
                successType='DELETE_ACCOUNT_REQUEST' />,
        );
    };

    return (
        <div className='delete-account-wrapper'>
            <h2 className='delete-account-title'>
                Delete Account
            </h2>

            <div className='delete-account-text-button'>
                <p className='delete-account-text'>
                    By deleting your account, you will permanently lose access to all your reports and account-related
                    information. Are you sure you want to proceed?
                </p>

                <button className='delete-my-account' onClick={openingDeleteModal}>
                    Delete my account
                </button>
            </div>
        </div>
    );
};

