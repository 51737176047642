import { Routers } from './root';
import { useSelector } from 'react-redux';
import { ToastFail, ToastSuccess } from 'utils';
import { Toast } from 'components';

function App() {
    const { httpOnError, httpOnSuccess } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
    }));
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error);

    return (
        <div>
            <Routers />
            <Toast
                type={toastSuccess ? 'success' : toastFail ? 'error' : ''}
                text={toastSuccess ? toastSuccess : toastFail ? toastFail : ''}
                info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ''}
            />
        </div>
    );
}

export default App;
