import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ButtonMain, InputMain, CheckboxMain } from 'components';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { EmailValidator, FindError, FindLoad, FindSuccess, PasswordValidator, useModal } from 'utils';
import { Svg } from 'assets';
import { WelcomeTo } from './welcomeTo';
import { LogIn } from './logIn';


const REQUEST_TYPE = 'SIGN_UP';

export const CreateAccount = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [iAgree, setIAgree] = useState(false);
    const { openModal } = useModal();
    const backError = FindError(REQUEST_TYPE);
    const signUpLoad = FindLoad(REQUEST_TYPE);
    const signUpSuccess = FindSuccess(REQUEST_TYPE);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('access-token');

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        clearErrors,
    } = useForm({
        defaultValues: {
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
    });

    useEffect(() => {
        if (token) {
            navigate('/');
        }
        dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        clearErrors();
    }, []);

    useEffect(() => {
        if (signUpSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
            openModal(<WelcomeTo />);
        }
    }, [signUpSuccess]);

    useEffect(() => {
        if (backError?.error) {
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
            setError('email', {
                type: 'required',
                message: 'User already exists',
            });
        }
    }, [backError]);

    const handleClickIcon = () => {
        setShowPassword(prev => !prev);
    };

    const handleClickConfirmIcon = () => {
        setShowConfirmPassword(prev => !prev);
    };

    const onSubmit = (data) => {
        if (data?.password === data?.confirmPassword && iAgree) {
            const sendingData = { ...data };
            delete sendingData?.confirmPassword;
            dispatch(authActions.signUp(sendingData));
        } else {
            setError('confirmPassword', {
                type: 'required',
                message: 'Password does not match with the confirmation',
            });
        }
    };

    return (
        <div className='create-acc-wrapper'>
            <div className='container'>
                <div className='create-acc-content'>
                    <div className='create-account-modal'>
                        <p className='create-account-modal-title'>
                            Create an Account
                        </p>

                        <p className='create-account-modal-desc'>
                            Welcome to ClaimFRV!
                        </p>

                        <form className='create-ac-form' onSubmit={handleSubmit(onSubmit)}>
                            <InputMain
                                name='fullName'
                                control={control}
                                maxLength={15}
                                placeholder='Full Name'
                                labelHeader='Full Name'
                                rules={{
                                    required: 'This Field is required',
                                }}
                                startAdornment={
                                    <Svg.Profile
                                        className={`'profile-svg' ${errors?.fullName?.message ? 'error-profile' : ''}`} />}
                            />

                            <InputMain
                                name='email'
                                control={control}
                                placeholder='Email'
                                labelHeader='Email'
                                rules={{
                                    required: 'This Field is required',
                                    pattern: {
                                        value: EmailValidator,
                                        message: 'Must be valid email',
                                    },
                                }}
                                startAdornment={<Svg.Sms />}
                            />

                            <InputMain
                                name='password'
                                type='password'
                                showPassword={showPassword}
                                control={control}
                                placeholder='Password'
                                labelHeader='Password'
                                rules={{
                                    required: 'This Field is required',
                                    pattern: {
                                        value: PasswordValidator,
                                        message: 'Not valid Password',
                                    },
                                }}
                                onClickIconEnd={handleClickIcon}
                                startAdornment={<Svg.Lock />}
                            />

                            <InputMain
                                name='confirmPassword'
                                type='password'
                                showPassword={showConfirmPassword}
                                control={control}
                                placeholder='Password Confirmation'
                                labelHeader='Password Confirmation'
                                rules={{
                                    required: 'This Field is required',
                                }}
                                onClickIconEnd={handleClickConfirmIcon}
                                startAdornment={<Svg.Lock />}
                            />

                            <div className='i-have-read'>
                                <CheckboxMain
                                    checked={iAgree}
                                    className='i-have-read-checkbox'
                                    onChange={() => setIAgree(prev => !prev)}
                                />
                                <p className='i-have-read-text'>
                                    I have read and agree to the
                                    <NavLink className='i-have-read-text-link' to='/terms' target="_blank">Terms of Use</NavLink>
                                    and
                                    <NavLink className='i-have-read-text-link' to='/privacy' target="_blank" >Privacy Policy</NavLink>
                                </p>
                            </div>

                            <ButtonMain
                                loading={signUpLoad}
                                text='Continue'
                                type='submit'
                                disabled={!iAgree}
                                className='create-acc-button'
                            />
                        </form>

                        <div className='already-have-block'>
                            <p className='already-have'>
                                Already have an account?

                            </p>

                            <p className='already-have-link' onClick={() => openModal(<LogIn />)}>
                                Sign In
                            </p>
                        </div>

                    </div>

                    {/*<div className='create-account-unlock-exclusive'>*/}
                    {/*    <h4 className='unlock-exclusive-title'>*/}
                    {/*        Unlock Exclusive Benefits with a Personal Account*/}
                    {/*    </h4>*/}

                    {/*    <div className='unlock-exclusive-cards-wrapper'>*/}
                    {/*        {unlockExclusive.map(({ title, Icon }, index) => (*/}
                    {/*            <div className='unlock-exclusive-card' key={index}>*/}
                    {/*                <Icon className='unlock-exclusive-car-icon' />*/}

                    {/*                <p className='unlock-exclusive-card-text'>*/}
                    {/*                    {title}*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        ))}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>
    );
};

