import React from 'react';
import { homeCardsInfo } from './constants';
import { Card } from './card';

export const HowWeHelp = () => {
    return (
        <div className='container'>
            <div className='how-we-help'>
                <div className='cards-info'>
                    {homeCardsInfo?.map(({ title, description, Icon }, index) => (
                        <Card
                            key={index}
                            title={title}
                            description={description}
                            Icon={Icon}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

