import { GET_ORDERS_REQUEST, SINGLE_ORDERS_REQUEST } from './orders.types';


export const getOrders = () => {
    return {
        type: GET_ORDERS_REQUEST,
    };
};
export const ordersPost = (id) => {
    return {
        type: SINGLE_ORDERS_REQUEST,
        payload: {
            id,
        },
    };
};

