import { Svg } from 'assets';

export const homeCardsInfo = [
  {
    Icon: Svg.TrustedPartner,
    title: 'AI-Driven Accuracy',
    description: `By Harnessing the power of advanced AI algorithms, we deliver highly accurate Fair Rental Value Estimates, ensuring precision in every report.`,
  },
  {
    Icon: Svg.Configuration,
    title: 'Streamlined Process',
    description: `Whether you are an insurance company or an adjuster, our user-friendly platform makes FRV reports quick and easy to obtain for insurance claims.`,
  },
  {
    Icon: Svg.Benefit,
    title: 'Secure & Accessible',
    description: `Receive reports directly to your email and access them at any time through your secure account. Rest assured that your data and reports are always secure and easily accessible. `,
  },
  {
    Icon: Svg.Analysis,
    title: 'Designed for Industry Professionals',
    description: `Specifically crafted for adjusters and insurers, our system streamlines the valuation process, offering features that cater to the unique demands of the insurance industry.`,
  },
];
