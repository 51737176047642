import React from 'react';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { AddressInput, ButtonMain, InputMain } from 'components';
import { useWindowSize } from 'utils';
import { Svg } from 'assets';

export const HomeBanner = () => {
    const [width] = useWindowSize();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        setValue,
    } = useForm({
        defaultValues: {
            address: '',
            email:  '',
        },
    });

    const onSubmit = (data) => {
        const params = {};
        data?.address ? params.address = data?.address : delete params.address;
        data?.email ? params.email = data?.email : delete params.email;
        navigate('/order-now', { state: params });
    };

    return (
        <div className='home-banner-wrapper'>
            <div className='container'>
                <div className='home-banner-box'>
                    <h1 className='banner-title'>
                        {'Instant AI-Powered Fair Rental Value For Insurance Companies and Adjusters'}
                    </h1>

                    {width > 1279 &&
                        <form className='address-email-button' onSubmit={handleSubmit(onSubmit)}>
                            <AddressInput
                                className='banner-input banner-input-border-radius'
                                control={control}
                                name='address'
                                placeholder='Enter Address'
                                customDesign={false}
                                setValue={setValue}
                            />

                            <InputMain
                                className='banner-input'
                                control={control}
                                name='email'
                                placeholder='Enter Email Address'
                                customDesign={false}
                            />

                            <ButtonMain
                                type='submit'
                                startAdornment={<Svg.Listing />}
                                text={width > 1279 ? 'Get Your FRV Report Now' : 'FRV Report'}
                                className='report-button'
                            />
                        </form>
                    }
                </div>
            </div>
        </div>
    );
};

