import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { Svg } from 'assets';


export const OrderSummary = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const deleteCoupon = () => {
    const params = { ...state };
    if (params?.coupon) delete params?.coupon;

    navigate('/order-now', { state: params });
  };

  return (
    <div className='order-summary-wrapper'>
      <div className='order-summary-content'>
        <h2 className='order-summary-title'>Order Summary</h2>

        <p className='order-summary-text'>
          Securely pay for your FRV report. Your payment information is encrypted and protected.
        </p>

        <div className='info_about-address_email'>
          {state?.address &&
            <div className='info_about-box'>
              <Svg.Calendar2Svg className='info_about-box_svg' />
              <p className='info_about-box_description'>
                Date Generated:
              </p>
              <p className='info_about-box_title'>
                {moment.utc().format('MM/DD/YYYY')}
              </p>
            </div>
          }

          {state?.claimNumber &&
            <div className='info_about-box'>
              <Svg.HashtagSvg className='info_about-box_svg' />

              <p className='info_about-box_description'>
                Claim Number:
              </p>

              <p className='info_about-box_title'>
                {state?.claimNumber}
              </p>
            </div>
          }

          {state?.address &&
            <div className='info_about-box'>
              <Svg.LocationSvg className='info_about-box_svg' />

              <p className='info_about-box_description'>
                Property Address:
              </p>

              <p className='info_about-box_title'>
                {state?.address}
              </p>
            </div>
          }

          {state?.email &&
            <div className='info_about-box'>
              <Svg.SmsTrackingSvg className='info_about-box_svg' />

              <p className='info_about-box_description'>
                Email:
              </p>

              <p className='info_about-box_title'>
                {state?.email}
              </p>
            </div>
          }
        </div>

        <div className='order-summary-report'>
          <div className='order-summary-report-header'>
            <p className='order-summary-report-header-text'>FRV Report</p>

            <p className='order-summary-report-header-text'>$149.00</p>
          </div>
        </div>

        {state?.coupon?.id &&
          <div className='coupon-wrapper'>
            <div className='coupon-info-wrapper'>
              <p className='coupon-info-text'>
                Coupon Applied <span style={{ fontSize: '14px', fontWeight: 400 }}>(100% off)</span>
              </p>

              <p className='coupon-info-count'>
                -$149.00
              </p>
            </div>

            <div className='coupon-cards-wrapper'>

              <div className='coupon-card'>
                <Svg.CouponSvg className='coupon-card-svg' />

                <p className='coupon-card-text'>
                  Coupon {state?.coupon?.code}
                </p>

                <Svg.Close className='coupon-card-delete-svg' onClick={deleteCoupon} />
              </div>
            </div>
          </div>
        }
      </div>

      <div className='order-summary-footer'>
        <div className='order-summary-footer-left'>
          <p className='order-summary-footer-left-text1'>Total</p>
        </div>

        <p className='order-summary-footer-right-text'>{state?.coupon?.id ? '$0.00' : '$149.00'}</p>
      </div>
    </div>
  );
};
