import {
    getOrders,
    ordersPost,
} from "./orders.action";

export { ordersReducer } from "./orders.reducer";
export { watchOrders } from "./orders.saga";

export const ordersActions = {
    getOrders,
    ordersPost
};
