/** Combined Sagas */

import { fork } from 'redux-saga/effects';
import { watchAuth } from 'store/auth';
import { watchOrders } from '../orders';

export const appSaga = function* startForman() {
    yield fork(watchAuth);
    yield fork(watchOrders);
};
