import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Svg } from 'assets';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { ButtonMain, InputMain } from 'components';
import { FindLoad, FindSuccess } from 'utils';

const ACTION_TYPE = 'RESET_PASSWORD'
export const ResetPassword = () => {
    const [showPasswords, setShowPassword] = useState({
        newPassword: false,
        confirmation: false,
    });
    const [resetToken, setResetToken] = useState('');
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE)
    const success = FindSuccess(ACTION_TYPE)

    const {
        handleSubmit,
        control,
    } = useForm({
        defaultValues: {
            newPassword: '',
            confirmation: '',
        },
    });
    const link = window.location.search;

    useEffect(() => {
        if (link.search('resetToken=') !== -1) {
            const pos = link.slice(link.search('resetToken='));
            const resetToken = pos.substring(11);
            setResetToken(resetToken);
        }
    }, []);

    useEffect(() => {
        if (success?.type === ACTION_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE))
            window.location.replace('/')
        }
    }, [success])

    const showingPassword = (path) => {
        setShowPassword(prev => ({
            ...prev,
            [path]: !prev[path],
        }));
    };

    const onSubmit = (data) => {
        const { newPassword, confirmation } = data;

        if (newPassword === confirmation) {
            const params = {
                token: resetToken,
                newPassword,
                confirmation,
            };
            dispatch(authActions.resetPassword(params));
        }
    };

    return (
        <div className='login-wrapper reset-wrapper'>

            <div className='login-content'>
                <h3 className='login-title'>
                    Forgot Your Password?
                </h3>

                <p className='forgot-desc'>
                    Please enter your email address and we&apos;ll send you a recovery email to reset your password.
                </p>

                <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
                    <InputMain
                        name='newPassword'
                        type='password'
                        showPassword={showPasswords.newPassword}
                        control={control}
                        placeholder='Password'
                        labelHeader='New Password'
                        rules={{
                            required: 'This Field is required',
                        }}
                        onClickIconEnd={() => showingPassword('newPassword')}
                        startAdornment={<Svg.Lock />}
                    />

                    <InputMain
                        name='confirmation'
                        type='password'
                        showPassword={showPasswords.confirmation}
                        control={control}
                        placeholder='Password'
                        labelHeader='Confirm New Password'
                        rules={{
                            required: 'This Field is required',
                        }}
                        onClickIconEnd={() => showingPassword('confirmation')}
                        startAdornment={<Svg.Lock />}
                    />

                    <ButtonMain
                        text='Sign In'
                        className='login-sign-in-button'
                        type='submit'
                        loading={loader}
                    />
                </form>
            </div>
        </div>
    );
};

