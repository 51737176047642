import React from 'react';
import { CircularProgress } from '@mui/material';

export const Loader = ({ styles }) => {
    return (
        <CircularProgress
            style={{
                    width: '20px',
                    height: '20px',
                    color: '#000',
                    ...styles,
                }
            }
        />
    );
};

