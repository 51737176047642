import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonMain, InputMain, TextAreaMain } from 'components';
import { EmailValidator, FindLoad, FindSuccess } from 'utils';
import { Svg } from 'assets';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { useDispatch } from 'react-redux';

export const ContactUs = () => {
    const dispatch = useDispatch();
    const success = FindSuccess('CONTACT_US');
    const loading = FindLoad('CONTACT_US');

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('CONTACT_US'));
            reset({
                name: '',
                email: '',
                message: '',
            });
        }
    }, [success]);

    const onSubmit = (data) => {
        dispatch(authActions.contactUs(data));
    };

    return (
        <div className='ContactUs-page'>
            <div className='container'>

                <div className='ContactUs-wrapper'>
                    <h2 className='ContactUs-title'>
                        Contact Us
                    </h2>

                    <p className='ContactUs-text'>
                        Our friendly team will answer you as soon as possible!
                    </p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputMain
                            name='name'
                            control={control}
                            placeholder='Full Name'
                            labelHeader='Full Name'
                            rules={{
                                required: 'This Field is required',
                            }}
                            startAdornment={
                                <Svg.Profile
                                    className={`profile-svg ${errors?.name?.message ? 'contact-error-profile' : ''}`} />}
                        />

                        <InputMain
                            name='email'
                            control={control}
                            placeholder='Email'
                            labelHeader='Email'
                            rules={{
                                required: 'This Field is required',
                                pattern: {
                                    value: EmailValidator,
                                    message: 'Must be valid email',
                                },
                            }}
                            startAdornment={<Svg.Sms />}
                        />

                        <TextAreaMain
                            name='message'
                            control={control}
                            placeholder='Your message here...'
                            labelHeader='Message'
                            className='contact-us-textarea'
                            rules={{
                                required: 'This Field is required',
                            }}
                        />

                        <ButtonMain
                            text='Sent'
                            type='submit'
                            loading={loading}
                            className='contact-us-send-btn'
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

