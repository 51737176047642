import React from 'react';
import { Svg } from 'assets';
export const CardReport = ({ singleField }) => {
    return (
        <div className='card-report-wrapper'>
            <div className='card-report-image-address'>
                <div className='card-report_image-block'>
                    <img src={Svg.NoImageTemp} alt='photo' />
                </div>
                <p className='card-report_address'>
                    {singleField?.streetAddress} {singleField?.city} {singleField?.state} {singleField?.zipcode}
                </p>
            </div>
            <ul className='card-report_list-block'>
                <li className='card-report_list-item'>
                    {singleField?.bedrooms} Beds, {singleField?.bathrooms} Bath
                </li>

                <li className='card-report_list-item'>
                    Dist* {singleField?.distance} Index* {singleField?.comps}
                </li>

                <li className='card-report_list-item'>
                    {singleField?.livingArea} Sqft. {singleField?.price} Rent
                </li>
            </ul>
        </div>
    );
};

