import React, { useEffect } from 'react';
import { ButtonMain } from 'components';
import { useDispatch } from 'react-redux';
import { httpRequestsOnSuccessActions } from 'store';
import { FindLoad, useModal } from 'utils';
import { Svg } from 'assets';

export const DeleteModal = ({ onDelete, loadType, successType }) => {
    const dispatch = useDispatch();
    const { close } = useModal();
    const loading = FindLoad(loadType);
    const success = FindLoad(successType);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(successType));
        }
    }, []);

    return (
        <div className='delete-modal-wrapper'>
            <Svg.CloseSvg
                className='delete-modal-close-button'
                onClick={() => close()}
            />

            <div className='delete-modal-content'>
                <h3 className='delete-modal-content-title'>
                    Delete Account
                </h3>

                <p className='delete-modal-content-text'>
                    Are you sure you want to delete your account?If you delete your account, you&apos;ll
                    permanently lose your profile.
                </p>

                <div className='delete-modal-content-buttons'>
                    <ButtonMain
                        text='Cancel'
                        cancel
                        onClick={() => close()}
                    />

                    <ButtonMain
                        text='Delete'
                        deleteButton
                        loading={loading}
                        onClick={onDelete}
                        loadStyles={{
                            color: '#FFF',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

