import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, httpRequestsOnErrorsActions } from 'store';
import { ButtonMain, InputMain } from 'components';
import { Svg } from 'assets';
import { EmailValidator, FindError, FindLoad, PasswordValidator } from 'utils';
import { useForm } from 'react-hook-form';


const CHANGE_PASSWORD_ACTION_TYPE = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_USER_ACTION_TYPE = 'CHANGE_CUSTOMER_INFO_REQUEST';
export const MyProfile = () => {
    const [editPersonalInfo, setEitPersonalInfo] = useState(false);
    const [changePassword, togglePassword] = useState(false);
    const [showPasswords, setShowingPasswords] = useState({
        password: false,
        newPassword: false,
        confirmation: false,
    });

    const dispatch = useDispatch();
    const myProfile = useSelector(state => state.auth.myProfile);
    const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
    const errorPasswords = FindError(CHANGE_PASSWORD_ACTION_TYPE);
    const userError = FindError(CHANGE_USER_ACTION_TYPE);
    const passwordChangedLoad = FindLoad(CHANGE_PASSWORD_ACTION_TYPE);
    const logOutLoad = FindLoad('LOG_OUT');
    const userChangedLoad = FindLoad(CHANGE_USER_ACTION_TYPE);
    const userFullName = myProfile?.fullName || userInfo?.fullName;
    const userEmail = myProfile?.email || userInfo?.email;

    const {
        handleSubmit,
        control,
        formState: { errors },
        clearErrors,
        setValue,
        setError,
    } = useForm({
        defaultValues: {
            fullName: userFullName || '',
            email: userEmail || '',
        },
    });

    const {
        handleSubmit: handleSubmitPasswords,
        control: controlPasswords,
        setError: setErrorPassword,
        clearErrors: clearErrorsPassword,
    } = useForm({
        defaultValues: {
            password: '',
            newPassword: '',
            confirmation: '',
        },
    });

    useEffect(() => {
        clearErrors('email')
    }, [])

    useEffect(() => {
        if (errorPasswords?.error === 'The new password does not match with the confirmation') {
            setErrorPassword('newPassword', {
                type: 'required',
                message: 'The new password does not match with the confirmation',
            });
        }
        if (errorPasswords?.error === 'User password does not match') {
            setErrorPassword('password', {
                type: 'required',
                message: 'User password does not match',
            });
        }
        if (userError?.error === 'User already exists') {
            dispatch(httpRequestsOnErrorsActions.removeError(CHANGE_USER_ACTION_TYPE))
            setError('email', {
                type: 'required',
                message: 'User already exists',
            });
        }
    }, [errorPasswords, userError]);


    const signOut = () => {
        dispatch(authActions.logOut());
    };

    const onSubmitPersonalInfo = (data) => {
        dispatch(authActions.changeCustomerInfo(myProfile?.id || userInfo?.id, data));
    };

    const onSubmitPassword = (data) => {
        if (data?.newPassword === data?.confirmation) {
            dispatch(authActions.changePassword(data));
        }
    };

    const showingPasswords = (path) => {
        setShowingPasswords(prev => ({
            ...prev,
            [path]: !prev[path],
        }));
    };

    return (
        <div className="my-profile-wrapper">
            <div className="my-profile-box">

                <div className="personal-info-wrapper">
                    <div className="personal-info-header">
                        <h4 className="info-title">
                            Personal Info 222
                        </h4>
                        {!editPersonalInfo ?
                            <ButtonMain
                                startAdornment={<Svg.EditSvg />}
                                text="Edit"
                                className="edit-button"
                                contained={false}
                                withOutPadding
                                onClick={() => {
                                    setEitPersonalInfo(true);
                                    setValue('fullName', userFullName);
                                    setValue('email', userEmail);
                                }}
                            />
                            :
                            <div className="cancel-save-block">
                                <ButtonMain
                                    text="Cancel"
                                    contained={false}
                                    withOutPadding
                                    className="cancel-button"
                                    textColor="#A3B2BD"
                                    onClick={() => {
                                        setEitPersonalInfo(false);
                                        clearErrors();
                                    }}
                                />
                                <ButtonMain
                                    text="Save"
                                    contained={false}
                                    withOutPadding
                                    loading={userChangedLoad}
                                    loadStyles={{ width: '12px', height: '12px' }}
                                    className="save-person"
                                    onClick={handleSubmit(onSubmitPersonalInfo)}
                                />
                            </div>
                        }
                    </div>

                    <div className="personal-info-content">
                        {!editPersonalInfo ?
                            <div>
                                <div className="personal-info-name-svg" style={{ marginBottom: 12 }}>
                                    <Svg.UserSvg /> <span className="personal-info-name">{userFullName}</span>
                                </div>
                                <div className="personal-info-name-svg" style={{ marginBottom: 18 }}>
                                    <Svg.Sms /> <span
                                    className="personal-info-name">{userEmail}</span>
                                </div>
                            </div> :

                            <div>
                                <InputMain
                                    name="fullName"
                                    control={control}
                                    placeholder="Full Name"
                                    maxLength={15}
                                    rules={{
                                        required: 'This Field is required',
                                    }}
                                    startAdornment={
                                        <Svg.Profile
                                            className={`profile-svg ${errors?.fullName?.message ? 'error-profile' : ''}`} />}
                                />

                                <InputMain
                                    name="email"
                                    control={control}
                                    placeholder="Email"
                                    rules={{
                                        required: 'This Field is required',
                                        pattern: {
                                            value: EmailValidator,
                                            message: 'Must be valid email',
                                        },
                                    }}
                                    startAdornment={<Svg.Sms />}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="change-password-wrapper">
                    <div className="personal-info-header">
                        <h4 className="info-title">
                            Change Password
                        </h4>
                        {!changePassword ?
                            <ButtonMain
                                startAdornment={<Svg.EditSvg />}
                                text="Edit"
                                withOutPadding
                                contained={false}
                                onClick={() => togglePassword(true)}
                            />
                            :
                            <div className="cancel-save-block">
                                <ButtonMain
                                    text="Cancel"
                                    contained={false}
                                    withOutPadding
                                    className="cancel-button"
                                    textColor="#A3B2BD"
                                    onClick={() => {
                                        togglePassword(false);
                                        clearErrorsPassword();
                                    }}
                                />

                                <ButtonMain
                                    text="Save"
                                    className="save-person"
                                    contained={false}
                                    loading={passwordChangedLoad}
                                    loadStyles={{ width: '12px', height: '12px' }}
                                    withOutPadding
                                    onClick={handleSubmitPasswords(onSubmitPassword)}
                                />
                            </div>
                        }
                    </div>

                    <div className="personal-info-content">
                        <p className="change-password-info-text">
                            Use strong password to keep your account secure.
                        </p>

                        {changePassword &&
                            <div>
                                <InputMain
                                    name="password"
                                    control={controlPasswords}
                                    type="password"
                                    placeholder="Current Password*"
                                    rules={{
                                        required: 'This Field is required',
                                        pattern: {
                                            value: PasswordValidator,
                                            message: 'Not valid Password',
                                        },
                                    }}
                                    startAdornment={<Svg.Lock />}
                                    showPassword={showPasswords.password}
                                    onClickIconEnd={() => showingPasswords('password')}
                                />

                                <InputMain
                                    name="newPassword"
                                    control={controlPasswords}
                                    type="password"
                                    placeholder="New Password*"
                                    rules={{
                                        required: 'This Field is required',
                                        pattern: {
                                            value: PasswordValidator,
                                            message: 'Not valid Password',
                                        },
                                    }}
                                    startAdornment={<Svg.Lock />}
                                    showPassword={showPasswords.newPassword}
                                    onClickIconEnd={() => showingPasswords('newPassword')}
                                />

                                <InputMain
                                    name="confirmation"
                                    control={controlPasswords}
                                    type="password"
                                    placeholder="Retype New Password*"
                                    rules={{
                                        required: 'This Field is required',
                                        pattern: {
                                            value: PasswordValidator,
                                            message: 'Not valid Password',
                                        },
                                    }}
                                    startAdornment={<Svg.Lock />}
                                    showPassword={showPasswords.confirmation}
                                    onClickIconEnd={() => showingPasswords('confirmation')}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <ButtonMain
                startAdornment={<Svg.SignOutSvg />}
                text="Sign Out"
                loading={logOutLoad}
                contained={false}
                isError={true}
                onClick={signOut}
                className="my-profile-sign-out-btn"
            />
        </div>
    );
};

