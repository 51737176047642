import React from 'react';

export const Terms = () => {
  return (
    <div className="terms-page">
      <div className="container">
        <h2 className="terms-title">Terms & Conditions</h2>

        <div className="terms-text-block">
          <p className="terms-text">
            1. Definitions Service: The service provided by ClaimFRV as described in the relative terms and on this platform.
            You: A person or entity that is registered with ClaimFRV to use the Services.
          </p>
          <p className="terms-text">
            2. Use of Our Service You must be at least 18 years of age to use our Service. By agreeing to these Terms, you represent and
            warrant that you are at least 18 years old. As a user, you agree to provide accurate, current, and complete information about
            yourself as prompted by our Service&apos;s registration forms.
          </p>
          <p className="terms-text">
            3. Your Account You are responsible for safeguarding the password that you use to access the Service and for any activities or
            actions under your password. You agree not to disclose your password to any third party. You must notify us immediately upon
            becoming aware of any breach of security or unauthorized use of your account.
          </p>
          <p className="terms-text">
            4. Intellectual Property Rights Other than the content you own, under these Terms, ClaimFRV and/or its licensors own all the
            intellectual property rights and materials contained in this Website. You are granted limited license only for purposes of
            viewing the material contained on this Website.
          </p>
          <p className="terms-text">
            5. Restrictions You are specifically restricted from all of the following: Publishing any Website material in any other media;
            Selling, sublicensing and/or otherwise commercializing any Website material; Publicly performing and/or showing any Website
            material; Using this Website in any way that is or may be damaging to this Website; Using this Website in any way that impacts
            user access to this Website.
          </p>
          <p className="terms-text">
            6. Your Content In these Terms and Conditions, “Your Content” shall mean any audio, video text, images, or other material you
            choose to display on this Website. By displaying Your Content, you grant ClaimFRV a non-exclusive, worldwide irrevocable,
            sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
          </p>
          <p className="terms-text">
            7. No warranties This Website is provided &quot;as is,&quot; with all faults, and ClaimFRV expresses no representations or warranties, of
            any kind related to this Website or the materials contained on this Website.
          </p>
          <p className="terms-text">
            8. Limitation of liability In no event shall ClaimFRV, nor any of its officers, directors, and employees, be held liable for
            anything arising out of or in any way connected with your use of this Website whether such liability is under contract.
          </p>
          <p className="terms-text">
            9. Indemnification You hereby indemnify to the fullest extent ClaimFRV from and against any and/or all liabilities, costs,
            demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these
            Terms.
          </p>
          <p className="terms-text">
            10. Severability If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be
            deleted without affecting the remaining provisions herein.
          </p>
          <p className="terms-text">
            11. Variation of Terms ClaimFRV is permitted to revise these terms at any time as it sees fit, and by using this Website you are
            expected to review these Terms on a regular basis.
          </p>
          <p className="terms-text">
            12. Assignment ClaimFRV is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without
            any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under
            these Terms.
          </p>
          <p className="terms-text">
            13. Entire Agreement These Terms constitute the entire agreement between ClaimFRV and you in relation to your use of this
            Website, and supersede all prior agreements and understandings.
          </p>
          <p className="terms-text">
            14. Governing Law & Jurisdiction These Terms will be governed by and interpreted in accordance with the laws of the
            State/Country of California, and you submit to the non-exclusive jurisdiction of the state and federal courts located in
            California for the resolution of any disputes.
          </p>
        </div>
      </div>
    </div>
  );
};
