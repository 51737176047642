import { Svg } from 'assets';

export const RoutersInfo = (loggedIn) => {
    if (loggedIn) {
        return [
            {
                title: 'Home',
                path: '/',
                Icon: Svg.HomeSvg,
                // alt: 'Members',
            },
            {
                title: 'Order Now',
                path: '/order-now',
                Icon: Svg.OrderNowSvg,
            },
            {
                title: 'Contact Us',
                path: '/contact-us',
                Icon: Svg.Messages,
            },
            {
                title: 'My Orders',
                path: '/orders',
                Icon: Svg.Messages,
            },
        ];
    } else {
        return [
            {
                title: 'Home',
                path: '/',
                Icon: Svg.HomeSvg,
                // alt: 'Members',
            },
            {
                title: 'Order Now',
                path: '/order-now',
                Icon: Svg.OrderNowSvg,
            },
            {
                title: 'Contact Us',
                path: '/contact-us',
                Icon: Svg.Messages,
            },
        ];
    }

};
