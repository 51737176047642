import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { HomeBanner, HowWeHelp, ReadyToDiscover, SimpleSteps, WorkWithUs } from './fragments';
import { AddressInput, ButtonMain, InputMain } from 'components';
import { Svg } from 'assets';
import { useWindowSize } from 'utils';

export const Home = () => {
    const location = useLocation();
    const [width] = useWindowSize();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        setValue,
    } = useForm({
        defaultValues: {
            address: '',
            email: '',
        },
    });

    useEffect(() => {
        if (location?.hash) {
            const element = document.getElementById(location?.hash?.slice(1));
            if (element) {
                element.scrollIntoView({ block: 'end', behavior: 'smooth' });
            }
        }

    }, [location]);

    const onSubmit = (data) => {
        const params = { };
        data?.address ? params.address = data?.address : delete params.address;
        data?.email ? params.email = data?.email : delete params.email;
        navigate('/order-now', { state: params });
    };

    return (
        <div className="home-page">
            <HomeBanner />
            {width < 1279 &&

                <form className="address-email-button" onSubmit={handleSubmit(onSubmit)}>
                    <AddressInput
                        className="banner-input"
                        control={control}
                        name="address"
                        placeholder="Enter Address"
                        customDesign={false}
                        setValue={setValue}
                    />
                    <InputMain
                        className="banner-input"
                        control={control}
                        name="email"
                        placeholder="Enter Email Address"
                        customDesign={false}
                    />

                    <ButtonMain
                        type="submit"
                        startAdornment={<Svg.Listing />}
                        text={width > 1279 ? 'Get Your FRV Report Now' : 'FRV Report'}
                        className="report-button"
                    />
                </form>
            }


            <HowWeHelp />

            <WorkWithUs />

            <SimpleSteps />

            <ReadyToDiscover />
        </div>
    );
};

