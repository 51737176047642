import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ButtonMain, InputMain } from 'components';
import { CheckEmail } from './checkEmail';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { EmailValidator, FindError, FindLoad, FindSuccess, useModal } from 'utils';
import { Images, Svg } from 'assets';


const REQUEST_TYPE = 'GET_RECOVERY_LINK';

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { openModal, close } = useModal();
    const recoverySuccess = FindSuccess(REQUEST_TYPE);
    const recoveryLoad = FindLoad(REQUEST_TYPE);
    const recoveryError = FindError(REQUEST_TYPE);

    const {
        handleSubmit,
        control,
        setError,
        watch,
    } = useForm({
        defaultValues: {
            email: '',
        },
    });

    useEffect(() => {
        return () => {
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        };
    }, []);

    useEffect(() => {
        if (recoverySuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
            close();
            openModal(<CheckEmail email={watch('email', false)}/>);
        }
    }, [recoverySuccess]);

    useEffect(() => {
        if (recoveryError) {
            setError('email', {
                type: 'required',
                message: recoveryError?.error,
            });
        }
    }, [recoveryError]);


    const onSubmit = (data) => {
        dispatch(authActions.getRecoveryLink(data.email));
    };

    return (
        <div className='login-wrapper'>
            <div className='forgot-img-block'>
                <img src={Images.LoginImg} alt='logIn Img' />
            </div>

            <div className='login-content'>
                <h3 className='login-title'>
                    Forgot Your Password?
                </h3>

                <p className='forgot-desc'>
                    Please enter your email address and we&apos;ll send you a recovery email to reset your password.
                </p>

                <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
                    <InputMain
                        name='email'
                        type='email'
                        control={control}
                        placeholder='Email'
                        labelHeader='Email Address'
                        rules={{
                            required: 'This Field is required',
                            pattern: {
                                value: EmailValidator,
                                message: 'Must be valid email',
                            },
                        }}
                        startAdornment={<Svg.Sms />}
                    />

                    <ButtonMain
                        text='Get Recovery Email'
                        className='login-sign-in-button'
                        type='submit'
                        loading={recoveryLoad}
                    />
                </form>
            </div>
        </div>
    );
};

