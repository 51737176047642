import React, { forwardRef, useRef } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

export const AddressInput = forwardRef(
    ({
         name, rules, control,  label, placeholder,labelHeader, setValue,
         type = 'text',customDesign = true, className, ...anotherProps
     }, ref) => {
        const inputRef = useRef();

        const handleSelect = (value) => {
            setValue(name, value);
        };


        return (
            <div
                className={`input-main-wrapper ${customDesign ? 'input-custom-wrapper' : ''} ${className ? className : ''}`}
                ref={inputRef}
            >
                <Controller
                    ref={ref}
                    control={control}
                    name={name}
                    rules={rules}
                    render={({ field, formState: { errors } }) => (
                        <PlacesAutocomplete
                            value={field?.value}
                            onChange={field?.onChange}
                            onSelect={handleSelect}
                            ref={ref}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <>
                                    {labelHeader
                                        &&
                                        <p className={`input-main-label-header ${errors[field?.name]?.message ? 'errorLabel' : ''}`}>
                                            {labelHeader}
                                            {rules?.required
                                                && <span className='input-main-label-header-required'>
                                                            *
                                                    </span>
                                            }
                                        </p>
                                    }

                                    <TextField
                                        ref={ref}
                                        {...anotherProps}
                                        {...field}
                                        {...getInputProps()}
                                        autoComplete='on'
                                        error={!!errors[field?.name]?.message}
                                        type={type}
                                        value={field.value}
                                        placeholder={placeholder}
                                        label={label}
                                    />
                                    <p className='error-message'>{errors[field?.name]?.message}</p>

                                    <div className='address-custom-input-wrapper'>
                                        {loading && <div className='address-loading-class'>Loading...</div>}

                                        <div className='address-custom-input-suggestions'>
                                            {suggestions.map((suggestion, index) => {
                                                const className =  'suggestion-item';
                                                return (
                                                    <p
                                                        key={index}
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                        })}>
                                                        {suggestion.description}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            )}

                        </PlacesAutocomplete>
                    )}
                />
            </div>
        );
    });

AddressInput.displayName = 'AddressInput';
