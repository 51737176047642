import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, IconButton, ListItem, Popover } from '@mui/material';
import { MyProfile } from '../myProfile';
import { ButtonMain } from 'components';
import { Footer } from '../footer/footer';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { RoutersInfo } from 'root';
import { FindLoad, FindSuccess, useModal } from 'utils';
import { LogIn } from '../auth';
import { Svg } from 'assets';


export const Layout = () => {
    const [openingMenu, setOpeningMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const layoutRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const myProfile = useSelector(state => state.auth.myProfile);
    const { openModal, close } = useModal();
    const logOutLoad = FindLoad('LOG_OUT');
    const logOutSuccess = FindSuccess('LOG_OUT');
    const token = localStorage.getItem('access-token');
    const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
    const userData = myProfile || userInfo;
    const open = Boolean(anchorEl);

    useEffect(() => {
        window.scroll(0, 0);
    }, [location?.pathname]);

    useEffect(() => {
        if (logOutSuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess('LOG_OUT'));
            setOpeningMenu(false)
        }
    }, [logOutSuccess]);


    const handleClick = () => {
        setAnchorEl(layoutRef.current);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const openLogInModal = () => {
        openModal(<LogIn />);
    };

    const createAccount = () => {
        navigate('sign-up');
    };

    const toggleMenu = () => {
        setOpeningMenu(prev => !prev);
    };

    const signUp = () => {
        toggleMenu();
        navigate('sign-up');
    };

    const signIn = () => {
        toggleMenu();
        openModal(<LogIn />);
    };

    const signOut = () => {
        dispatch(authActions.logOut());
    };

    const goToHome = () => {
        navigate('/');
        close();
        setOpeningMenu(false);
    };

    const list = () => {
        return (
            <div className='menu-lists'>
                <div>
                    {RoutersInfo(!!token).map(({ title, path, Icon }, index) => (
                        <ListItem
                            onClick={toggleMenu}
                            onKeyDown={toggleMenu}
                            key={index}
                        >
                            <NavLink
                                key={index}
                                to={path}
                                className={({ isActive }) =>
                                    isActive ? 'header-link active' : 'header-link'
                                }
                            >
                                <Icon className='header-link-svg' />
                                {title}
                            </NavLink>
                        </ListItem>
                    ))}

                    {token &&
                        <ListItem
                            onClick={toggleMenu}
                            onKeyDown={toggleMenu}
                        >
                            <div className='header-link-user' onClick={handleClick}>
                                <Svg.UserSvg />
                                <p>{userData?.fullName}</p>
                            </div>
                        </ListItem>
                    }

                </div>

                <div className='menu-lists-button-block'>
                    {!token ?
                        <>
                            <ButtonMain
                                text='Sign Up'
                                contained={false}
                                onClick={signUp}
                            />

                            <button
                                className='menu-lists-button-sign-in'
                                onClick={signIn}
                            >
                                Sign In
                            </button>
                        </> :
                        <ButtonMain
                            startAdornment={<Svg.SignOutSvg />}
                            text='Sign Out'
                            loading={logOutLoad}
                            contained={false}
                            isError={true}
                            onClick={signOut}
                            className='drawer-sign-out'
                        />
                    }
                </div>
            </div>
        );
    };

    return (
        <div className='layout-wrapper'>
            <div className='layout-header'>
                <div className='container'>
                    <div className='layout-box' ref={layoutRef}>
                        <Svg.Logo className='logo-svg' onClick={goToHome} />

                        <div className='header-right-side'>
                            <div className='header-right-side-desktop'>
                                <nav className='header-navigation'>
                                    {RoutersInfo(!!token).map(({ title, path }, index) => (
                                        <NavLink
                                            key={index}
                                            to={path}
                                            className={({ isActive }) =>
                                                isActive ? 'header-link active' : 'header-link'
                                            }
                                        >
                                            {title}
                                        </NavLink>
                                    ))}
                                </nav>

                                {!token ?
                                    <div className='header-buttons-block'>
                                        <button
                                            className='sign-up-button'
                                            onClick={openLogInModal}
                                        >
                                            Sign In
                                        </button>

                                        <ButtonMain
                                            className='header-button'
                                            text='Sign Up'
                                            contained={false}
                                            onClick={createAccount}
                                        />
                                    </div>
                                    :
                                    <div>

                                        <div className='loggedIn-header'
                                             onClick={handleClick}
                                        >
                                            <Svg.UserSvg />
                                            {userData?.fullName}
                                        </div>
                                        {anchorEl &&
                                            <Popover
                                                id='popover-my-profile'
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <MyProfile />
                                            </Popover>
                                        }
                                    </div>
                                }

                            </div>

                            <div className='header-right-side-mobile'>
                                <IconButton
                                    aria-label='more'
                                    id='long-button'
                                    aria-haspopup='true'
                                    onClick={toggleMenu}
                                >
                                    {openingMenu ?
                                        <Svg.Close className='header-right-side-mobile-svg' />
                                        :
                                        <Svg.BurgerMenu className='header-right-side-mobile-svg' />}
                                </IconButton>

                                <Drawer
                                    sx={{
                                        top: 80,
                                    }}
                                    transitionDuration={110}
                                    className='header-menu-drawer'
                                    anchor='left'
                                    open={openingMenu}
                                    onClose={toggleMenu}
                                >
                                    {list()}
                                </Drawer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='main-content'>
                {<Outlet />}
            </div>

            <Footer />
        </div>
    );
};

