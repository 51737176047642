import React from 'react';
import { useNavigate } from 'react-router';
import { ButtonMain } from 'components';
import { Svg } from 'assets';

export const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className='not-found-page'>
            <Svg.NotFoundSvg className='not-found-svg' />

            <p className='not-found-page-text'>
                Whoops! Page not found
            </p>

            <ButtonMain
                className='not-found-page-button'
                text='Back to homepage'
                withOutPadding
                onClick={() => navigate('/')}
            />
        </div>
    );
};

